<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row>
<!--           <v-col cols="12">
            <v-text-field
              class="offset-7"
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              >
            </v-text-field>
          </v-col>
 -->          <v-col cols="12">
            <v-data-table
              class="monitor-table"
              :headers="headers"
              :items="devsReps"
              :search="search"
              item-key="uuid"
              @current-items="getFiltered"
              >
              <template v-slot:item="{ item, isExpanded, expand }">
                <!--
                <tr :class="{ 'light-blue accent-1': isExpanded }" @click="expand(!isExpanded)">
                -->
                <tr>
                  <td>{{ item.name }}</td>
                  <td>
                    {{ item.status }}
                    <span v-if="item.layer.num">, слой №{{item.layer.num}}</span>
                  </td>
                  <td>{{ item.i }}</td>
                  <td>{{ item.u }}</td>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>П</td>
                          <td>К</td>
                          <td>С</td>
                        </tr>
                        <tr>
                          <td>{{item.process.devCommon}}</td>
                          <td>{{item.card.devCommon}}</td>
                          <td>{{item.layer.devCommon}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
              <!--
                <template v-slot:expanded-item="{ item, headers }">
                  <tr>
                    <td :colspan="headers.length" class="pa-3">
                      <table class="v-table yellow lighten-4" style="border: 1px solid rgba(0,0,0,0.12);">
                        <thead>
                          <tr>
                            <th></th>
                            <th class="text-center">Отклонения %</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Процесс: {{item.process.name}}</td>
                            <td>
                              <table class="v-table">
                                <tbody>
                                  <tr>
                                    <td>I</td>
                                    <td>{{item.process.CritUp}}</td>
                                    <td>{{item.process.CritDown}}</td>
                                    <td>{{item.process.AllowUp}}</td>
                                    <td>{{item.process.AllowDown}}</td>
                                  </tr>
                                  <tr>
                                    <td>U</td>
                                    <td>{{item.Process.CritUpU}}</td>
                                    <td>{{item.Process.CritDownU}}</td>
                                    <td>{{item.Process.AllowUpU}}</td>
                                    <td>{{item.Process.AllowDownU}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>Карта: {{item.Card.Name}}</td>
                            <td>
                              <table class="v-datatable v-table">
                                <tbody>
                                  <tr>
                                    <td>I</td>
                                    <td>{{item.Card.CritUpI}}</td>
                                    <td>{{item.Card.CritDownI}}</td>
                                    <td>{{item.Card.AllowUpI}}</td>
                                    <td>{{item.Card.AllowDownI}}</td>
                                  </tr>
                                  <tr>
                                    <td>U</td>
                                    <td>{{item.Card.CritUpU}}</td>
                                    <td>{{item.Card.CritDownU}}</td>
                                    <td>{{item.Card.AllowUpU}}</td>
                                    <td>{{item.Card.AllowDownU}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>Слой: {{item.Layer.Name}}</td>
                            <td>
                              <table class="v-datatable v-table">
                                <tbody>
                                  <tr>
                                    <td>I</td>
                                    <td>{{item.Layer.CritUpI}}</td>
                                    <td>{{item.Layer.CritDownI}}</td>
                                    <td>{{item.Layer.AllowUpI}}</td>
                                    <td>{{item.Layer.AllowDownI}}</td>
                                  </tr>
                                  <tr>
                                    <td>U</td>
                                    <td>{{item.Layer.CritUpU}}</td>
                                    <td>{{item.Layer.CritDownU}}</td>
                                    <td>{{item.Layer.AllowUpU}}</td>
                                    <td>{{item.Layer.AllowDownU}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import Paho from 'paho-mqtt'

  export default {
    data: () => ({
      mqttClient: new Paho.Client(
        'ws://broker.mqttdashboard.com:8000/mqtt',
        'clientId-' + Math.random().toString(16).substr(2, 10)
      ),
      headers: [
        { text: 'Пост', value: 'name' },
        { text: 'Статус', value: 'status' },
        { text: 'I', value: 'i', width: '50px' },
        { text: 'U', value: 'u', width: '50px' },
        { text: 'Отклонения', value: 'deviations', align: 'center' },
      ],
      search: '',
      filteredItems: [],
      subscribedTopics: [],
      devsReps: [],
      refreshDevsTimer: null,
      devReport: {
        uuid: '',
        name: '',
        status: 'Оффлайн',
        i: '',
        u: '',
        process: {},
        card: {},
        layer: {},
        timestamp: 0,
      },
    }),
    computed: {
      ...mapState('organization', { orgId: state => state.profile.id }),
    },
    methods: {
      mqttMessage(message) {
        let devData = JSON.parse(message.payloadString)
        devData.timestamp = Date.now()
        let index = this.devsReps.findIndex((item) => item.uuid === devData.uuid)
        if (index === -1) return
        this.devsReps.splice(index, 1, devData)
      },
      getFiltered(items) {
        this.filteredItems = items
        // check dev to sub
        //this.subscribe()
      },
      getOrgDevices() {
        this.$axios.get('device/device/index', {
          params: { orgId: this.orgId }
        }).then(r => {
          r.data.forEach(device => {
            let dev = JSON.parse(JSON.stringify(this.devReport))
            this.devsReps.push(Object.assign(
              dev,
              {
                uuid: device.uuid,
                name: device.name.name,
                timestamp: Date.now()
              }
            ))
          })
          this.mqttClient.connect({
            onSuccess: () => (this.subscribe()),
            reconnect: true
          })
        }).catch(() => {})
      },
      unsubscribe() {
        this.subscribedTopics.forEach(t => {
          this.mqttClient.unsubscribe(t)
        })
        this.subscribedTopics = []
      },
      subscribe() {
        if (!this.mqttClient.isConnected()) return
        this.unsubscribe()
        this.filteredItems.forEach(item => {
          let topic = 'manufacture/device/report/' + item.uuid
          this.mqttClient.subscribe(topic, { qos: 0 })
          this.subscribedTopics.push(topic)
        })
      },
      refreshDevices() {
        this.filteredItems.forEach(item => {
          if ((Date.now() - item.timestamp) > 5000) {
            item.status = 'Оффлайн'
          }
        })
      }
    },
    mounted: function () {
      this.getOrgDevices()
      this.refreshDevsTimer = setInterval(this.refreshDevices, 1000)
      this.mqttClient.onMessageArrived = this.mqttMessage
    },
    beforeDestroy () {
      this.unsubscribe()
      try{  this.mqttClient.disconnect() } catch(e) {}
      clearInterval(this.refreshDevsTimer)
    }
  }
</script>

<style>
  .monitor-table table tr td table:not(.v-table) tr:last-child td {
    border-bottom: 0px !important;
  }
  .monitor-table table tr td table.v-table tr td tr:last-child td {
    border-bottom: 0px !important;
  }
  .monitor-table table tr td table.v-table tr:hover {
    background-color: inherit !important;
  }
</style>
